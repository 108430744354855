import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import Slider from 'react-slick';
import './style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useContainerDimensions } from "../../extras/_modules/script.js";
// const TILE_SIZE = 300;
// const GAP_BETWEEN_TILES = 25;
function CarouselTitle(props) {
    if (!props.title)
        return (_jsx(_Fragment, {}));
    return (_jsx("h1", { className: "carousel-title centered", children: props.title }));
}
const Carousel = (props) => {
    const compRef = useRef(null);
    const { width } = useContainerDimensions(compRef);
    const calculateSliderSettings = () => {
        // Always show at least 1 element
        const elementsToShow = Math.max(1, Math.floor(width / (props.elementWidth + props.gapSize)));
        // Container should always be the elements + gap
        const containerSize = (elementsToShow * props.elementWidth) + (props.gapSize * (elementsToShow - 1));
        // Offset should always be half of the gapsize (if it's an odd number, it has issues but oh well)
        const sliderOffset = (props.gapSize) / 2;
        return {
            elementsToShow,
            containerSize,
            sliderOffset
        };
    };
    const config = calculateSliderSettings();
    const sliderSettings = {
        className: "slider-bg",
        dotsClass: "dots-class",
        dots: true,
        arrows: false,
        infinite: true,
        speed: 1000,
        swipeToSlide: true,
        rows: 1,
        slidesToShow: config.elementsToShow,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000
    };
    const sliderStyle = `
	.slick-list {
		margin: -${config.sliderOffset}px;
	}
	.slick-slide > div {
		padding: ${config.sliderOffset}px;
	}
	.carousel-container {
		min-width: ${config.containerSize}px;
		max-width: ${config.containerSize}px;
	}
`;
    return (_jsx("div", { ref: compRef, className: "expand", children: _jsxs("div", { className: "carousel-container", children: [_jsx(CarouselTitle, { title: props.title, visibleTiles: config.elementsToShow }), _jsx("style", { children: sliderStyle }), _jsx(Slider, { ...sliderSettings, children: props.elements })] }) }));
};
export default Carousel;
