import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import PropTypes from 'prop-types';
export default class Container extends React.Component {
    static propTypes = {
        children: PropTypes.node
    };
    render() {
        return (_jsx("div", { id: "query-container", children: _jsx("div", { id: "width-limit", children: this.props.children }) }));
    }
}
