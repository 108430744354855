import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import CodeBlock from "../../codeBlock";
import { useWindowDimensions } from "../../../extras/_modules/script.js";
import "./style.css";
function ProjectNPM(props) {
    if (!props.npm)
        return (_jsx(_Fragment, {}));
    return (_jsx("div", { className: "project-npm", children: _jsx("a", { href: `https://npmjs.com/package/${props.npm}`, children: _jsx("img", { alt: "npm stats", src: `https://nodei.co/npm/${props.npm}.png?compact=true`, loading: "eager" }) }) }));
}
function ProjectButtons(props) {
    const buttonLinks = [];
    if (props.blog)
        buttonLinks.push(_jsx(Link, { to: "./blog", className: "project-button blog-button", children: "Blog" }, "blog"));
    if (props.docs)
        buttonLinks.push(_jsx(Link, { to: "./docs", className: "project-button docs-button", children: "Docs" }, "docs"));
    buttonLinks.push(_jsx("a", { className: "project-button github-button", href: props.github, target: "_blank", rel: "noreferrer", children: "GitHub" }, "github"));
    return (_jsx("div", { id: "project-buttons", children: buttonLinks }));
}
function ProjectLogoLinks(props) {
    return (_jsxs("section", { id: "project-links", children: [_jsx("img", { className: "project-logo", alt: `${props.displayName} logo`, loading: "eager", src: props.img }), _jsx(ProjectNPM, { npm: props.npm }), _jsx(ProjectButtons, { github: props.github, docs: props.docs, blog: props.blog })] }));
}
function ExampleCode(props) {
    if (!props.exampleCode || !props.codeLang)
        return (_jsx(_Fragment, {}));
    return (_jsx(CodeBlock, { language: "javascript", children: props.exampleCode }));
    ;
}
function ProjectTitle(props) {
    return (_jsxs("section", { id: "project-info", children: [_jsx("h1", { className: "project-title", children: props.displayName }), _jsx("p", { className: "project-desc", children: props.fullDesc }), _jsx(ExampleCode, { exampleCode: props.exampleCode, codeLang: props.codeLang })] }));
}
function ProjectAbout(props) {
    return (_jsxs("section", { id: "project-about", children: [_jsx("h2", { className: "project-extra-title", children: "About" }), _jsx("div", { className: "p-container", children: props.about })] }));
}
function ProjectWhy(props) {
    return (_jsxs("section", { id: "project-why", children: [_jsx("h2", { className: "project-extra-title", children: "Why?" }), _jsx("div", { className: "p-container", children: props.why })] }));
}
export default function ProjectsPage(props) {
    const { width } = useWindowDimensions();
    if (width > 950) {
        return (_jsx("div", { className: "content project", children: _jsxs("div", { className: "project-page", children: [_jsx(ProjectTitle, { ...props }), _jsx(ProjectLogoLinks, { ...props }), _jsx(ProjectWhy, { ...props }), _jsx(ProjectAbout, { ...props })] }) }));
    }
    // Mobile Look
    return (_jsx("div", { className: "content project", children: _jsxs("div", { className: "project-page", children: [_jsx(ProjectLogoLinks, { ...props }), _jsx(ProjectTitle, { ...props }), _jsx(ProjectAbout, { ...props }), _jsx(ProjectWhy, { ...props })] }) }));
}
