import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./style.css";
const checklist = [
    {
        id: "premium",
        title: "Premium Buffs",
        checkers: [
            { id: "value", labelName: "Value Pack" },
            { id: "kama", labelName: "Blessing of Kamasylve" },
            { id: "oldmoon", labelName: "Old Moon Scroll" },
            { id: "loml", labelName: "LOML Buffs" },
            { id: "node", labelName: "Node War Buff" }
        ]
    },
    {
        id: "character",
        title: "Character Gear & Skills",
        checkers: [
            { id: "skilll", labelName: "Skill Preset" },
            { id: "addon", labelName: "Skill Addons" },
            { id: "artifact", labelName: "Artifact Set" },
            { id: "crystal", labelName: "Crystal Preset" },
            { id: "bsr", labelName: "Lock/ Unlock BSR Skills" }
        ]
    },
    {
        id: "consumables",
        title: "Consumables",
        checkers: [
            { id: "cron", labelName: "Simple/ Exquisite Cron Meal" },
            { id: "alchemy", labelName: "Alchemy Stone" },
            { id: "elixir", labelName: "Elixirs & Perfumes" },
            { id: "experience", labelName: "EXP Increase Scrolls" },
            { id: "house", labelName: "House Buff" }
        ]
    },
    {
        id: "droprate",
        title: "Drop Buffs",
        checkers: [
            { id: "agris", labelName: "Agris" },
            { id: "lootscroll", labelName: "Loot Scroll" },
            { id: "tent", labelName: "Tent" },
            { id: "guild", labelName: "Guild Buffs" }
        ]
    },
    {
        id: "special",
        title: "Special Buffs",
        checkers: [
            { id: "church", labelName: "Church Buffs" },
            { id: "villa", labelName: "Villa Buff" },
            { id: "krogdolo", labelName: "Krogdolo Horse Buff" },
            { id: "carolin", labelName: "Carolin Buff" }
        ]
    },
    {
        id: "misc",
        title: "Miscellaneous",
        checkers: [
            { id: "arsha", labelName: "Arsha? Enable PvP" },
            { id: "fairy", labelName: "Fairy Auto-Potion" },
            { id: "weight", labelName: "Empty Inventory" },
            { id: "pets", labelName: "Pet Looting" }
        ]
    }
];
function ChecklistInput(props) {
    return (_jsxs("div", { className: "checkbox-wrapper", children: [_jsx("input", { id: props.id, type: "checkbox" }), _jsx("label", { htmlFor: props.id, children: props.labelName })] }));
}
function ChecklistTile(props) {
    const checklistForTile = [];
    const checklists = props.checkers.map(check => {
        checklistForTile.push(check.id);
        return _jsx(ChecklistInput, { id: check.id, labelName: check.labelName }, check.id);
    });
    const selector = (checklist, checked) => {
        for (const check of checklist) {
            const element = document.getElementById(check);
            element.checked = checked;
        }
    };
    return (_jsxs("div", { className: "checklist-tile", children: [_jsx("h3", { className: "checklist-title", children: props.title }), _jsx("div", { className: "checklist-checks", children: checklists }), _jsxs("div", { className: "button-container", children: [_jsx("button", { onClick: selector.bind(null, checklistForTile, true), className: "checklist-button", id: "select-all", children: "Check All" }), _jsx("button", { onClick: selector.bind(null, checklistForTile, false), className: "checklist-button", id: "deselect-all", children: "Uncheck All" })] })] }));
}
export default function GrindChecklist() {
    const checklistTiles = checklist.map(tileData => {
        return _jsx(ChecklistTile, { checkers: tileData.checkers, title: tileData.title }, tileData.id);
    });
    return (_jsxs("div", { className: "content checklist", children: [_jsx("h1", { className: "centered project-title", children: "Grind Checklist" }), _jsx("div", { className: "checklist-container", children: checklistTiles })] }));
}
