import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import './style.css';
import { MenuLeftIcon, MenuRightIcon } from "../svgs";
import { useWindowDimensions, TABLET_WIDTH } from "../../extras/_modules/script.js";
/*
if ("ontouchstart" in document.documentElement){
    // touch device
}
*/
// Componants
function ProjectsSection(props) {
    const { width } = useWindowDimensions();
    const projects = props.projects.map(project => {
        return (_jsx(Link, { to: project.url, children: project.name.toUpperCase() }, project.id));
    });
    if (width >= TABLET_WIDTH) {
        return (_jsxs("div", { id: "nav-projects", className: "nav-section nav-dropdown", children: [_jsx("a", { href: void (0), children: _jsx(MenuLeftIcon, {}) }), _jsx("div", { id: "nav-projects-dropdown", className: "nav-dropdown-content", children: projects })] }));
    }
    // Mobile
    return (_jsxs("div", { id: "nav-projects", className: "nav-section nav-dropdown supress-hover", children: [_jsx("a", { href: void (0), children: _jsx(MenuLeftIcon, {}) }), _jsx("div", { id: "nav-projects-dropdown", className: "nav-dropdown-content", children: projects })] }));
}
function EmptySection() {
    return (_jsx("div", { className: "nav-section nav-empty" }));
}
function SocialMobileBars() {
    return (_jsx("div", { id: "mobile-bars", className: "nav-section", children: _jsx("a", { href: void (0), children: _jsx(MenuRightIcon, {}) }) }));
}
function SocialLinks(props) {
    const socials = props.socials.filter(social => social.enable).map(social => {
        return (_jsx("a", { href: "http://" + social.url + social.username, target: "_blank", rel: "noreferrer", children: social.icon }, social.title));
    });
    // Mobile
    return (_jsx("div", { className: "socials-container", children: _jsx("div", { id: "nav-social", className: "nav-section", children: socials }) }));
}
function SocialsSection(props) {
    const { width } = useWindowDimensions();
    if (width >= TABLET_WIDTH) {
        return (_jsxs(_Fragment, { children: [_jsx(EmptySection, {}), _jsx(SocialLinks, { socials: props.socials })] }));
    }
    // Mobile
    return (_jsxs(_Fragment, { children: [_jsx(EmptySection, {}), _jsx(SocialMobileBars, {}), _jsx(SocialLinks, { socials: props.socials })] }));
}
export default function Navbar(props) {
    const { width } = useWindowDimensions();
    useEffect(() => {
        const handleProjectsHamberg = () => {
            const dropdown = document.getElementById("nav-projects-dropdown");
            if (dropdown.classList.contains("show-projects"))
                return dropdown.classList.remove("show-projects");
            dropdown.classList.add("show-projects");
        };
        const navProjectDiv = document.getElementById("nav-projects");
        navProjectDiv.onclick = handleProjectsHamberg;
        const toggleSocials = () => {
            const navbar = document.getElementById("nav-social");
            if (navbar.classList.contains("slide-in"))
                return navbar.classList.remove("slide-in");
            navbar.classList.add("slide-in");
        };
        const mobileBarsDiv = document.getElementById("mobile-bars");
        if (mobileBarsDiv)
            mobileBarsDiv.onclick = toggleSocials;
    });
    if (width >= TABLET_WIDTH) {
        return (_jsxs("nav", { id: "navbar", children: [_jsx(ProjectsSection, { projects: props.projects }), _jsx(SocialsSection, { socials: props.socials })] }));
    }
    // Mobile
    return (_jsxs("nav", { id: "navbar", className: "mobile", children: [_jsx(ProjectsSection, { projects: props.projects }), _jsx(SocialsSection, { socials: props.socials })] }));
}
