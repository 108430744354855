import { jsx as _jsx } from "react/jsx-runtime";
import Markdown from 'markdown-to-jsx';
import { Link } from 'react-router-dom';
import CodeBlock from '../../codeBlock';
import "./style.css";
const entityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
    "/": "&#x2F;",
    "`": "&#x60;",
    "=": "&#x3D;"
};
function MarkdownWrapper(props) {
    return _jsx("div", { className: "md-content", children: props.children });
}
export default function DocsPage(props) {
    const handleLink = (that) => {
        // Same Page Links
        if (that.href.startsWith("#"))
            return _jsx("a", { href: that.href, children: that.children });
        // Same docs links
        if (that.href.startsWith("./"))
            return _jsx(Link, { to: props.baseUrl + that.href.slice(1), children: that.children });
        // Internal Links
        if (that.href.startsWith("/"))
            return _jsx(Link, { to: that.href, children: that.children });
        // External Links
        return _jsx("a", { className: "open-in-new", href: that.href, target: "_blank", rel: "noreferrer", children: that.children });
    };
    const docsOptions = {
        h1: {
            component: ({ children, ...props }) => _jsx("h1", { ...props, children: children }),
            props: {
                className: "md-h1"
            }
        },
        h2: {
            component: ({ children, ...props }) => _jsx("h2", { ...props, children: children }),
            props: {
                className: "md-h2"
            }
        },
        h3: {
            component: ({ children, ...props }) => _jsx("h3", { ...props, children: children }),
            props: {
                className: "md-h3"
            }
        },
        h4: {
            component: ({ children, ...props }) => _jsx("h4", { ...props, children: children }),
            props: {
                className: "md-h4"
            }
        },
        h5: {
            component: ({ children, ...props }) => _jsx("h5", { ...props, children: children }),
            props: {
                className: "md-h5"
            }
        },
        h6: {
            component: ({ children, ...props }) => _jsx("h6", { ...props, children: children }),
            props: {
                className: "md-h6"
            }
        },
        p: {
            component: ({ children, ...props }) => _jsx("p", { ...props, children: children }),
            props: {
                className: "md-p"
            }
        },
        ol: {
            component: ({ children, ...props }) => _jsx("ol", { ...props, children: children }),
            props: {
                className: "md-ol"
            }
        },
        ul: {
            component: ({ children, ...props }) => _jsx("ul", { ...props, children: children }),
            props: {
                className: "md-ul"
            }
        },
        code: {
            component: ({ children, ...props }) => _jsx("code", { ...props, children: children }),
            props: {
                className: "md-code"
            }
        },
        iframe: {
            component: ({ children, ...props }) => _jsx("iframe", { ...props, children: children }),
            props: {
                className: "md-iframe"
            }
        },
        pre: {
            component: ({ children, ...props }) => _jsx(CodeBlock, { ...props, children: children })
        },
        a: {
            component: handleLink
        }
    };
    return (_jsx("div", { className: "md-container", children: _jsx(Markdown, { options: { overrides: docsOptions, wrapper: MarkdownWrapper, namedCodesToUnicode: entityMap }, children: props.markdown }) }));
}
