import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ProjectTile from "../../projectTile";
import Carousel from "../../carousel";
import TitleAndLogo from "../../logo";
import { projectsData } from "../../../extras/_modules/script.js";
import "./style.css";
export default function App() {
    const projectTileData = projectsData.filter(item => item.isProject);
    const projectTiles = projectTileData.map(project => _jsx(ProjectTile, { project: project }, project.id));
    return (_jsxs("div", { className: "content home", children: [_jsx(TitleAndLogo, {}), _jsx(Carousel, { elements: projectTiles, elementWidth: 300, gapSize: 24, title: "" })] }));
}
