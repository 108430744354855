import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import PropTypes from 'prop-types';
import "./style.css";
import hljs from 'highlight.js';
hljs.configure({
    ignoreUnescapedHTML: true
});
export default class CodeBlock extends React.Component {
    static propTypes = {
        children: (PropTypes.string || PropTypes.node),
        language: PropTypes.string
    };
    componentDidMount() {
        this.highlightCode();
    }
    componentDidUpdate() {
        this.highlightCode();
    }
    highlightCode() {
        const nodes = document.querySelectorAll(".code-overflow > pre > code");
        nodes.forEach(node => {
            node.removeAttribute("data-highlighted");
            hljs.highlightBlock(node);
        });
    }
    render() {
        const child = this.props.children;
        const language = (typeof child === "string") ? this.props.language : child.props.className.replace("lang-", "").replace("md-code", "");
        return (_jsx("div", { className: "code-overflow", children: _jsx("pre", { className: "hljs-pre-codeblock", children: _jsx("code", { className: `hljs-codeblock language-${language}`, children: this.props.children }) }) }));
    }
}
